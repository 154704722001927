import { createContext, ReactNode } from "preact/compat";
import { useEffect, useState, useContext, useMemo } from "preact/hooks";
import { trimStart, debounce, isEqual, pick } from "lodash-es";
import { backOff } from "exponential-backoff";
import {
	PictureConfiguration,
	BrickedPictureOutput,
	Palette,
	createPremadeKitPalettes,
	loadBitmapFromUrl,
	BuildSources,
	BitmapMask,
	createBitmapMaskFromBitmap,
	constrainImageZoomOffset,
} from "@brickme/project-core/src";
import { useApiClient } from "~/api/context.tsx";
import createDefaultPictureConfiguration from "./create-default-picture-configuration.ts";
import {
	BuildWorkerIncomingData,
	BuildWorkerOutgoingData,
} from "./build/build-worker-data.ts";
import BuildWorker from "./build/build-worker.ts?worker";
import { SourceImage } from "./source-image.ts";
import uploadTempFile from "./upload-temp-file.ts";

type PicturePatch = Partial<
	Omit<PictureConfiguration, "updatedAt" | "pen" | "numberOfBricks">
>;

type PictureValue = {
	readonly picture: PictureConfiguration;
	readonly resetPicture: () => void;
	readonly patchPicture: (patch: PicturePatch) => void;
	readonly patchPictureDebounced: (patch: PicturePatch) => void;
};

const PictureContext = createContext<PictureValue | undefined>(undefined);

type PaletteValue = {
	readonly kitsPalette: Palette;
	readonly kitPlusExtrasPalette: Palette;
};

const PaletteContext = createContext<PaletteValue | undefined>(undefined);

type BrickedBuildContextValue = {
	readonly brickedBuild: BrickedPictureOutput | undefined;
};

const BrickedBuildContext = createContext<BrickedBuildContextValue | undefined>(
	undefined,
);

type RenderModeValue = {
	readonly activeSourceRenderModeRequests: ReadonlySet<string>;
	readonly requestSourceRenderMode: (id: string) => void;
	readonly withdrawSourceRenderModeRequest: (id: string) => void;
};

const RenderModeContext = createContext<RenderModeValue | undefined>(undefined);

type BuildSourcesValue = {
	readonly sourceImage: SourceImage & {
		readonly mediaFileKey: string | undefined;
	};
	readonly backgroundMaskImage:
		| {
				readonly bitmap: BitmapMask;
				readonly mediaFileKey: string;
		  }
		| undefined;
};

const BuildSourcesContext = createContext<BuildSourcesValue | undefined>(
	undefined,
);

type PublicFilterJobQuery = {
	readonly publicFilterJob: {
		readonly resultImageUrl: string;
	};
};

type PublicFilterJobVariables = {
	readonly id: string;
};

type StartBackgroundMaskJobMutation = {
	readonly startBackgroundMaskJob: {
		readonly id: string;
	};
};

type StartBackgroundMaskJobVariables = {
	readonly sourceImageKey: string;
};

type BackgroundMaskLoad =
	| {
			readonly type: "pending";
	  }
	| {
			readonly type: "loading";
			readonly publicFilterJobId: string;
	  }
	| {
			readonly type: "loaded";
			readonly result: {
				readonly bitmap: BitmapMask;
				readonly mediaFileKey: string;
			};
	  };

type PictureProviderProps = {
	readonly sourceImage: SourceImage;
	readonly numberOfKits: number;
	readonly systemPalette: Palette;
	readonly children: ReactNode;
};

function EditorProvider({
	sourceImage,
	numberOfKits,
	systemPalette,
	children,
}: PictureProviderProps) {
	// Render mode
	const [activeSourceRenderModeRequests, setActiveSourceRenderModeRequests] =
		useState<Set<string>>(new Set());
	const renderMode = useMemo(
		(): RenderModeValue => ({
			activeSourceRenderModeRequests,
			requestSourceRenderMode: (id: string) => {
				setActiveSourceRenderModeRequests((r) =>
					r.has(id) ? r : new Set(r).add(id),
				);
				// Clear build. Without this we get a flash of "old" bricked when
				// we turn back on.
				setBrickedBuild({ brickedBuild: undefined });
			},
			withdrawSourceRenderModeRequest: (id: string) =>
				setActiveSourceRenderModeRequests((r) => {
					if (!r.has(id)) {
						return r;
					}
					const newValue = new Set(r);
					const wasRemoved = newValue.delete(id);
					if (!wasRemoved) {
						return r;
					}
					return newValue;
				}),
		}),
		[activeSourceRenderModeRequests],
	);

	// Build worker
	const [brickedBuildValue, setBrickedBuild] =
		useState<BrickedBuildContextValue>({
			brickedBuild: undefined,
		});
	const { brickedBuild } = brickedBuildValue;
	// Note: https://stackoverflow.com/a/72681399/7743183
	// you should even never start more Workers than navigator.hardwareConcurrency - 1.
	// Failing to do so, your Workers's concurrency would be done through task-switching
	// instead of true parallelism, ant that would incur a significant performance penalty.
	// Daniel: In reality, nearly all users will have multi-core these days. We have a
	// main thread renderer so do check concurrency there.
	const [buildWorker] = useState(() => new BuildWorker());
	useEffect(() => {
		const onMessage = (e: MessageEvent) => {
			const data = e.data as BuildWorkerOutgoingData;
			switch (data.type) {
				case "bricked-result":
					setBrickedBuild({ brickedBuild: data.output });
					break;
				default:
					console.error("Editor got unknown message", data);
			}
		};
		buildWorker.addEventListener("message", onMessage);

		return () => {
			buildWorker.removeEventListener("message", onMessage);
			buildWorker.terminate();
		};
	}, [buildWorker]);

	// Palette
	const paletteValue = useMemo(() => {
		return createPremadeKitPalettes(systemPalette, numberOfKits);
	}, [numberOfKits, systemPalette]);
	const { kitPlusExtrasPalette, kitsPalette } = paletteValue;
	useEffect(() => {
		buildWorker.postMessage({
			type: "set-palette",
			palette: kitPlusExtrasPalette,
		} as BuildWorkerIncomingData);
	}, [buildWorker, kitPlusExtrasPalette]);

	// Picture
	const [picture, setPicture] = useState(() =>
		createDefaultPictureConfiguration({ numberOfKits, kitsPalette }),
	);
	const value = useMemo(() => {
		const patchPicture = (patch: PicturePatch) => {
			setPicture((p) => {
				const picture1 = {
					...p,
					...patch,
				};
				const picture2 = {
					...picture1,
					numberOfBricks: {
						width: picture.basePlateSize * picture1.numberOfBasePlates.width,
						height: picture.basePlateSize * picture1.numberOfBasePlates.height,
					},
				};
				const picture3 = {
					...picture2,
					// If changing numberOfBasePlates or baseplateSize then this can become invalid
					imageZoomOffset: constrainImageZoomOffset(
						sourceImage.bitmap,
						picture2,
						picture2.imageZoomOffset,
					),
				};
				if (isEqual(picture3, p)) {
					return p;
				}
				return picture3;
			});
		};
		return {
			picture,
			patchPicture,
			patchPictureDebounced: debounce(patchPicture, 50, {
				leading: true,
				trailing: true,
			}),
			resetPicture: () => {
				setPicture(
					createDefaultPictureConfiguration({ numberOfKits, kitsPalette }),
				);
			},
		};
	}, [sourceImage.bitmap, picture, numberOfKits, setPicture]);

	// Transfer picture to worker when changes. Pause builds when source rendering mode
	const isSourceRenderModeActive = activeSourceRenderModeRequests.size > 0;
	useEffect(() => {
		if (isSourceRenderModeActive) {
			buildWorker.postMessage({
				type: "clear-picture",
			} as BuildWorkerIncomingData);
		} else {
			buildWorker.postMessage({
				type: "set-picture",
				picture,
			} as BuildWorkerIncomingData);
		}
	}, [isSourceRenderModeActive, buildWorker, picture]);

	// Saving source image
	const apiClient = useApiClient();
	const [sourceImageKey, setSourceImageKey] = useState<string | undefined>();
	useEffect(() => {
		const abortController = new AbortController();
		(async () => {
			const newTempFileKey = await uploadTempFile(apiClient, sourceImage, {
				signal: abortController.signal,
			});
			setSourceImageKey(newTempFileKey);
		})();
		return () => {
			abortController.abort();
		};
	}, [apiClient, sourceImage]);

	// Background mask
	const [backgroundMask, setBackgroundMask] = useState<BackgroundMaskLoad>({
		type: "pending",
	});
	useEffect(() => {
		if (!brickedBuild) {
			return;
		}

		if (backgroundMask.type === "loaded") {
			return;
		}

		const { operationsMissingSources } = brickedBuild;
		if (!operationsMissingSources.includes("Remove background")) {
			return;
		}

		if (backgroundMask.type === "pending") {
			if (!sourceImageKey) {
				return;
			}

			const abortController = new AbortController();
			(async () => {
				const { startBackgroundMaskJob } = await apiClient.mutate<
					StartBackgroundMaskJobMutation,
					StartBackgroundMaskJobVariables
				>(
					`mutation ($sourceImageKey: String!) {
						startBackgroundMaskJob(sourceImageKey: $sourceImageKey) {
							id
						}
					}`,
					{
						sourceImageKey,
					},
					{
						signal: abortController.signal,
					},
				);

				setBackgroundMask({
					type: "loading",
					publicFilterJobId: startBackgroundMaskJob.id,
				});
			})();
			return () => {
				abortController.abort();
			};
		}

		if (backgroundMask.type === "loading") {
			const abortController = new AbortController();
			(async () => {
				const foundResultImageUrl = await backOff(
					async () => {
						const {
							publicFilterJob: { resultImageUrl },
						} = await apiClient.query<
							PublicFilterJobQuery,
							PublicFilterJobVariables
						>(
							`query ($id: ID!) {
							publicFilterJob(id: $id) {
								resultImageUrl
							}
						}`,
							{
								id: backgroundMask.publicFilterJobId,
							},
							{
								signal: abortController.signal,
							},
						);
						if (!resultImageUrl) {
							throw new Error("No result image url");
						}

						return resultImageUrl;
					},
					{
						delayFirstAttempt: false,
						jitter: "none",
						startingDelay: 500,
						maxDelay: 3_000,
						numOfAttempts: 100,
						timeMultiple: 1.5,
					},
				);

				const bitmap = await loadBitmapFromUrl(foundResultImageUrl);
				setBackgroundMask({
					type: "loaded",
					result: {
						bitmap: createBitmapMaskFromBitmap(bitmap),
						mediaFileKey: trimStart(new URL(foundResultImageUrl).pathname, "/"),
					},
				});
			})();
			return () => {
				abortController.abort();
			};
		}
	}, [apiClient, brickedBuild, backgroundMask, sourceImageKey]);
	const buildSourcesValue = useMemo(
		(): BuildSourcesValue => ({
			sourceImage: {
				...sourceImage,
				mediaFileKey: sourceImageKey,
			},
			backgroundMaskImage:
				backgroundMask.type === "loaded" ? backgroundMask.result : undefined,
		}),
		[sourceImage, backgroundMask, sourceImageKey],
	);

	// Send build sources to worker
	const buildSources = useMemo(
		(): BuildSources => ({
			original: sourceImage.bitmap,
			backgroundMask:
				backgroundMask.type === "loaded"
					? backgroundMask.result.bitmap
					: undefined,
			enhanceFaces: undefined,
			colorisation: undefined,
			facesMask: undefined,
		}),
		[sourceImage.bitmap, backgroundMask],
	);
	useEffect(() => {
		buildWorker.postMessage({
			type: "set-build-sources",
			...buildSources,
		} as BuildWorkerIncomingData);
	}, [buildWorker, buildSources]);

	return (
		<PaletteContext.Provider value={paletteValue}>
			<BuildSourcesContext.Provider value={buildSourcesValue}>
				<PictureContext.Provider value={value}>
					<RenderModeContext.Provider value={renderMode}>
						<BrickedBuildContext.Provider value={brickedBuildValue}>
							{children}
						</BrickedBuildContext.Provider>
					</RenderModeContext.Provider>
				</PictureContext.Provider>
			</BuildSourcesContext.Provider>
		</PaletteContext.Provider>
	);
}

function usePicture() {
	const context = useContext(PictureContext);
	if (context === undefined) {
		throw new Error("usePicture must be used within a PictureProvider");
	}
	return context;
}

function useCurrentBrickedBuild() {
	const context = useContext(BrickedBuildContext);
	if (context === undefined) {
		throw new Error("useCurrentBrickedBuild must be used within context");
	}
	return context.brickedBuild;
}

function useActiveRenderMode() {
	const context = useContext(RenderModeContext);
	if (context === undefined) {
		throw new Error("useActiveRenderMode must be used within context");
	}
	return {
		activeRenderMode:
			context.activeSourceRenderModeRequests.size > 0 ? "source" : "bricked",
		activeSourceRenderModeRequests: context.activeSourceRenderModeRequests,
	};
}

function useRenderModeControls() {
	const context = useContext(RenderModeContext);
	if (context === undefined) {
		throw new Error("useRenderModeControls must be used within context");
	}
	return pick(
		context,
		"requestSourceRenderMode",
		"withdrawSourceRenderModeRequest",
	);
}

function useSourceImageBitmap() {
	const context = useContext(BuildSourcesContext);
	if (context === undefined) {
		throw new Error("useSourceImage must be used within context");
	}
	return context.sourceImage.bitmap;
}

function useBuildSourcesMediaKeys() {
	const context = useContext(BuildSourcesContext);
	if (context === undefined) {
		throw new Error("useSourceImage must be used within context");
	}
	return {
		sourceImageKey: context.sourceImage.mediaFileKey,
		backgroundMaskImageKey: context.backgroundMaskImage?.mediaFileKey,
	};
}

function usePalettes() {
	const context = useContext(PaletteContext);
	if (context === undefined) {
		throw new Error("usePalettes must be used within context");
	}
	return context;
}

export {
	useBuildSourcesMediaKeys,
	usePalettes,
	EditorProvider,
	useRenderModeControls,
	useSourceImageBitmap,
	useActiveRenderMode,
	useCurrentBrickedBuild,
	usePicture,
};
