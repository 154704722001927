import UploadImage from "./upload-image.tsx";
import { SetupConfig } from "./config.ts";

type SetupTryProps = {
	readonly onComplete: (config: SetupConfig) => void;
	readonly onCancel: () => void;
};

function SetupTry({ onComplete, onCancel }: SetupTryProps) {
	return (
		<UploadImage
			onComplete={(sourceImage) =>
				onComplete({
					sourceImage,
					numberOfKits: 1,
					code: undefined,
				})
			}
			onCancel={onCancel}
		/>
	);
}

export default SetupTry;
