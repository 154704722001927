import { useMemo, useState, useRef } from "preact/hooks";
import { JSX } from "preact/jsx-runtime";
import { unknownToString } from "@dhau/lang-extras";

type SubmittingFormState = {
	readonly type: "submitting";
};

type ErrorFormState = {
	readonly type: "error";
	readonly error: string;
};

type PendingFormState = {
	readonly type: "pending";
};

type SuccessFormState = {
	readonly type: "success";
	readonly values: Record<string, FormDataEntryValue>;
};

type FormState =
	| SubmittingFormState
	| ErrorFormState
	| PendingFormState
	| SuccessFormState;

const pendingFormState: PendingFormState = { type: "pending" };

type FormValues = Record<string, FormDataEntryValue>;

type Options = {
	readonly onSubmit: (values: FormValues) => Promise<void> | void;
};

function useForm({ onSubmit }: Options) {
	const onSubmitRef = useRef(onSubmit);
	onSubmitRef.current = onSubmit;

	const [formState, setFormState] = useState<FormState>(pendingFormState);

	const formRef = useRef<HTMLFormElement>(null);

	return useMemo(
		() => ({
			formRef,
			isWorking: formState.type === "submitting",
			successValues:
				formState.type === "success" ? formState.values : undefined,
			resetFormState: () => {
				setFormState(pendingFormState);
			},
			formError: formState.type === "error" ? formState.error : undefined,
			formProps: {
				ref: formRef,
				onSubmit: async (e: JSX.TargetedEvent<HTMLFormElement>) => {
					e.preventDefault();

					const formData = new FormData(e.currentTarget);
					const values: Record<string, FormDataEntryValue> = {};
					for (const [key, value] of formData.entries()) {
						values[key] = value;
					}

					setFormState({ type: "submitting" });
					try {
						await onSubmitRef.current(values);
						setFormState({ type: "success", values });
					} catch (error) {
						setFormState({ type: "error", error: unknownToString(error) });
					}
				},
			},
		}),
		[formState, formRef],
	);
}

export type { FormValues };
export default useForm;
