import { ApiClient } from "~/api/client.ts";

type TempMediaPresignedUrlQuery = {
	readonly tempMediaPresignedUrl: {
		readonly key: string;
		readonly url: string;
	};
};

type TempMediaPresignedUrlVariables = {
	readonly fileName: string;
	readonly contentType: string;
};

type FileOptions = {
	readonly fileName: string;
	readonly contentType: string;
	readonly content: ArrayBuffer;
};

type Options = {
	readonly signal: AbortSignal;
};

async function uploadTempFile(
	apiClient: ApiClient,
	{ fileName, contentType, content }: FileOptions,
	{ signal }: Options,
) {
	const { tempMediaPresignedUrl } = await apiClient.query<
		TempMediaPresignedUrlQuery,
		TempMediaPresignedUrlVariables
	>(
		`query (
            $fileName: String!
            $contentType: String!
        ) {
            tempMediaPresignedUrl(
                fileName: $fileName
                contentType: $contentType
				acl: "public"
            ) {
                key
                url
            }
        }`,
		{ fileName, contentType },
		{ signal },
	);
	await fetch(tempMediaPresignedUrl.url, {
		method: "PUT",
		headers: {
			"Content-Type": contentType,
		},
		body: content,
		signal,
	});
	return tempMediaPresignedUrl.key;
}

export default uploadTempFile;
