import { ReactNode } from "preact/compat";
import { useState } from "preact/hooks";
import Button from "~/components/button.tsx";
import Logo from "~/components/logo.tsx";
import FaqsModal from "~/features/content/faqs-modal.tsx";
import ReviewsModal from "~/features/content/reviews-modal.tsx";
import { useTranslationControls, useTranslator } from "~/i18n/context.tsx";
import classes from "./layout.module.css";

type LayoutProps = {
	readonly children: ReactNode;
};

function Layout({ children }: LayoutProps) {
	const [faqsOpen, setFaqsOpen] = useState(false);
	const [reviewsOpen, setReviewsOpen] = useState(false);
	const { languageOptions, currentLanguage, setCurrentLanguage } =
		useTranslationControls();
	const t = useTranslator();
	return (
		<>
			<div className={classes.layout}>
				<header>
					<Logo />
					<div className={classes["language-control"]}>
						{t("Language")}:
						<div className={classes.select}>
							<select
								value={currentLanguage}
								onChange={(e) => setCurrentLanguage(e.currentTarget.value)}
							>
								{languageOptions.map((o) => (
									<option key={o} value={o}>
										{o}
									</option>
								))}
							</select>
							<span className={classes["select-focus"]}></span>
						</div>
					</div>
				</header>
				<main className={classes.main}>{children}</main>
				<footer>
					<Button
						type="button"
						variant="secondary"
						onClick={() => setReviewsOpen(true)}
					>
						{t("Reviews")}
					</Button>
					<Button
						type="button"
						variant="secondary"
						onClick={() => setFaqsOpen(true)}
					>
						{t("FAQs")}
					</Button>
				</footer>
			</div>
			<FaqsModal open={faqsOpen} onRequestClose={() => setFaqsOpen(false)} />
			<ReviewsModal
				open={reviewsOpen}
				onRequestClose={() => setReviewsOpen(false)}
			/>
		</>
	);
}

export default Layout;
