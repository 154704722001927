import Modal, { ModalProps } from "~/components/modal.tsx";
import { useTranslator } from "~/i18n/context.tsx";

type RemoveColourModalProps = Pick<ModalProps, "open" | "onRequestClose"> & {
	readonly onConfirm: () => void;
};

function RemoveColourModal({
	open,
	onConfirm,
	onRequestClose,
}: RemoveColourModalProps) {
	const t = useTranslator();
	return (
		<Modal
			open={open}
			onRequestClose={onRequestClose}
			title={t("Remove colour")}
			size="medium"
			cancelActionButton
			actionButtons={[
				{
					label: t("Remove colour"),
					variant: "primary",
					type: "button",
					onClick: onConfirm,
				},
			]}
		>
			{t(`Go back to only colours in your kit. Praesent ultricies at dui eu
			pulvinar. Sed est sapien, auctor eget mi id, sollicitudin porttitor
			tellus. Ut dui nisi, dictum id odio non, ultrices consequat sem.
			Vestibulum elementum pharetra libero vel convallis.`)}
		</Modal>
	);
}

export default RemoveColourModal;
