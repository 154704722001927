import { ReactNode } from "preact/compat";
import classes from "./form-row.module.css";

type FormRowProps = {
	readonly children: ReactNode;
};

function FormRow({ children }: FormRowProps) {
	return <div className={classes["form-row"]}>{children}</div>;
}

export default FormRow;
