import { ReactNode } from "preact/compat";
import { JSX } from "preact/jsx-runtime";
import { clsx } from "clsx";
import classes from "./button.module.css";

type BaseButtonProps = {
	readonly variant: "primary" | "secondary" | "text" | "danger";
	readonly size?: "large" | "snug" | "small";
	readonly children: ReactNode;
	readonly fullWidth?: boolean;
	readonly circular?: boolean;
	readonly disabled?: boolean;
	readonly working?: boolean;
	readonly className?: string;
	readonly "aria-label"?: string;
};

type GeneralButtonProps = BaseButtonProps & {
	readonly type: "button";
	readonly onClick: (event: JSX.TargetedMouseEvent<HTMLButtonElement>) => void;
};

type LinkButtonProps = BaseButtonProps & {
	readonly href: string;
	readonly newWindow?: boolean;
};

type SubmitButtonProps = BaseButtonProps & {
	readonly type: "submit";
};

type ButtonProps = SubmitButtonProps | GeneralButtonProps | LinkButtonProps;

function Button({
	variant,
	disabled,
	working,
	children,
	fullWidth,
	circular,
	size = "large",
	className,
	"aria-label": ariaLabel,
	...rest
}: ButtonProps) {
	const commonAttrs = {
		"aria-label": ariaLabel,
		className: clsx(
			classes.btn,
			classes[`btn-${variant}`],
			classes[`btn-${size}`],
			fullWidth && classes["btn-full-width"],
			circular && classes["btn-circular"],
			working && classes["btn-working"],
			className,
		),
	};

	if (!("type" in rest)) {
		if (disabled) {
			return (
				<span aria-disabled={disabled} {...commonAttrs}>
					{children}
				</span>
			);
		}

		return (
			<a
				href={rest.href}
				target={rest.newWindow ? "_blank" : undefined}
				rel={rest.newWindow ? "noopener noreferrer" : undefined}
				aria-disabled={disabled}
				{...commonAttrs}
			>
				{children}
			</a>
		);
	}

	if (rest.type === "submit") {
		return (
			<button type="submit" disabled={disabled} {...commonAttrs}>
				{children}
			</button>
		);
	}

	return (
		<button
			type="button"
			onClick={"onClick" in rest ? rest.onClick : undefined}
			disabled={disabled}
			{...commonAttrs}
		>
			{children}
		</button>
	);
}

export type { ButtonProps, SubmitButtonProps };
export default Button;
