import { RenderRunner } from "./render-runner-type.ts";
import { createRenderWorkerBridge } from "./render-worker-bridge.ts";

// Code split out so lazy load just the renderer we need. Atm it's not having an effect
// because project-core not tree shaking properly. But it will when that's fixed.
// Bridge not lazy because its tiny

let createRenderer: () => Promise<RenderRunner<HTMLCanvasElement>>;

// Note: https://stackoverflow.com/a/72681399/7743183
// you should even never start more Workers than navigator.hardwareConcurrency - 1.
// Failing to do so, your Workers's concurrency would be done through task-switching
// instead of true parallelism, ant that would incur a significant performance penalty.

// The check for 2 here assumes we're using main thread + build worker.

// Safari Dec 2022 started supporting transferControlToOffscreen
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
if (
	!HTMLCanvasElement.prototype.transferControlToOffscreen ||
	navigator.hardwareConcurrency <= 2
) {
	console.warn("Using main thread renderer");
	createRenderer = () =>
		import("./render-runner.ts").then(({ createRenderRunner }) =>
			createRenderRunner({
				pendingRenderMethod: "timeout",
			}),
		);
} else {
	createRenderer = () => Promise.resolve(createRenderWorkerBridge());
}

export { createRenderer };
