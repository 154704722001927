import { findOnlyOrThrow } from "@dhau/lang-extras";
import Modal, { ModalProps } from "~/components/modal.tsx";
import { useTranslator } from "~/i18n/context.tsx";
import { useCurrentBrickedBuild, usePalettes } from "../context.tsx";
import classes from "./buy-colour-bricks-modal.module.css";

type BuyColourBricksModalProps = Pick<ModalProps, "open" | "onRequestClose">;

function BuyColourBricksModal({
	open,
	onRequestClose,
}: BuyColourBricksModalProps) {
	const t = useTranslator();
	const build = useCurrentBrickedBuild();
	const { kitsPalette, kitPlusExtrasPalette } = usePalettes();
	const brickCounts = build?.brickCounts;
	const colourBricksRequired = Object.entries(brickCounts ?? {})
		.filter(
			([colour]) => !kitsPalette.some((p) => p.brick.hexString === colour),
		)
		.map(([colour, { count }]) => ({
			brick: findOnlyOrThrow(
				kitPlusExtrasPalette,
				(p) => p.brick.hexString === colour,
				`Couldn't find colour ${colour} in kit plus extras palette`,
			).brick,
			count,
		}));
	return (
		<Modal
			open={open}
			onRequestClose={onRequestClose}
			title={t("Buy bricks")}
			size="medium"
		>
			{colourBricksRequired.length > 0 ? (
				<>
					<>
						<p>
							{t(
								`You have the following extra colours. Here are the links to purchase them:`,
							)}
						</p>
						<ul className={classes["brick-list"]}>
							{colourBricksRequired.map(({ brick, count }) => (
								<li key={brick.hexString}>
									<div
										className={classes["brick-preview"]}
										style={{ backgroundColor: brick.hexString }}
									/>{" "}
									x {count.toLocaleString()} -{" "}
									<a
										href="http://www.amazon.com.au"
										target="_blank"
										rel="noopener noreferrer"
									>
										{t("Buy now")}
									</a>
								</li>
							))}
						</ul>
					</>
				</>
			) : (
				<>
					{t(`You have all the colours you need for this design in your kit.`)}
				</>
			)}
		</Modal>
	);
}

export default BuyColourBricksModal;
