import { useState } from "preact/hooks";
import { compact } from "lodash-es";
import { useTranslator } from "~/i18n/context.tsx";
import BottomMenu from "../bottom-menu.tsx";
import { Mode } from "../mode.ts";
import AddColourModal from "./add-colour-modal.tsx";
import BuyAKitModal from "./buy-a-kit-modal.tsx";
import BuyIcon from "./buy-icon.tsx";
import ColourIcon from "../../../components/icons/colour-icon.tsx";
import InstructionsIcon from "./instructions-icon.tsx";
import RemoveColourModal from "./remove-colour-modal.tsx";
import BuyColourBricksModal from "./buy-colour-bricks-modal.tsx";
import GetInstructionsModal from "./get-instructions-modal.tsx";
import { usePalettes, usePicture } from "../context.tsx";
import { createDefaultPaletteMode } from "../create-default-picture-configuration.ts";

type CompleteMenuProps = {
	readonly mode: Mode;
	readonly code: string | undefined;
	readonly numberOfKits: number;
	readonly onChangeMode: (mode: Mode) => void;
};

function CompleteMenu({
	mode,
	onChangeMode,
	numberOfKits,
	code,
}: CompleteMenuProps) {
	const t = useTranslator();
	const [open, setOpen] = useState<
		| "instructions"
		| "add-colour"
		| "remove-colour"
		| "buy-kit"
		| "buy-colour-bricks"
	>();
	const { patchPicture } = usePicture();
	const { kitsPalette } = usePalettes();
	return (
		<>
			<BuyAKitModal
				open={open === "buy-kit"}
				onRequestClose={() => setOpen(undefined)}
			/>
			<AddColourModal
				open={open === "add-colour"}
				onRequestClose={() => setOpen(undefined)}
				onConfirm={() => onChangeMode("colour")}
			/>
			<RemoveColourModal
				open={open === "remove-colour"}
				onRequestClose={() => setOpen(undefined)}
				onConfirm={() => {
					onChangeMode("kit-only");
					patchPicture({
						paletteMode: createDefaultPaletteMode({ kitsPalette }),
					});
				}}
			/>
			<BuyColourBricksModal
				open={open === "buy-colour-bricks"}
				onRequestClose={() => setOpen(undefined)}
			/>
			{code && (
				<GetInstructionsModal
					open={open === "instructions"}
					numberOfKits={numberOfKits}
					onRequestClose={() => setOpen(undefined)}
					code={code}
				/>
			)}
			<BottomMenu
				buttons={compact([
					code
						? {
								id: "get-instructions",
								label: t("Get instructions"),
								icon: <InstructionsIcon />,
								onClick: () => setOpen("instructions"),
							}
						: {
								id: "buy-kit",
								label: t("Buy a kit"),
								icon: <BuyIcon />,
								onClick: () => setOpen("buy-kit"),
							},
					mode === "kit-only" &&
						!!code && {
							id: "add-colour",
							label: t("Add colours"),
							icon: <ColourIcon />,
							onClick: () => setOpen("add-colour"),
						},
					...(mode === "colour"
						? [
								{
									id: "buy-colours",
									label: t("Buy colour bricks"),
									icon: <BuyIcon />,
									onClick: () => setOpen("buy-colour-bricks"),
								},
								{
									id: "remove-colour",
									label: t("Remove colours"),
									icon: <ColourIcon />,
									onClick: () => setOpen("remove-colour"),
								},
							]
						: []),
				])}
			/>
		</>
	);
}

export default CompleteMenu;
