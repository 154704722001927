import Modal, { ModalProps } from "~/components/modal.tsx";
import { useTranslator } from "~/i18n/context.tsx";

type ConfirmRestartModalProps = Pick<ModalProps, "open" | "onRequestClose"> & {
	readonly onConfirm: () => void;
};

function ConfirmRestartModal({
	open,
	onConfirm,
	onRequestClose,
}: ConfirmRestartModalProps) {
	const t = useTranslator();
	return (
		<Modal
			open={open}
			onRequestClose={onRequestClose}
			title={t("Are you sure?")}
			size="small"
			cancelActionButton
			actionButtons={[
				{
					label: t("Yes, restart"),
					type: "button",
					onClick: onConfirm,
					variant: "danger",
				},
			]}
		>
			{t(
				"All your progress will be lost and you'll have to start again by uploading a new image.",
			)}
		</Modal>
	);
}

export default ConfirmRestartModal;
