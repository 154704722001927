import { useContext, createContext, ReactNode } from "preact/compat";
import isFacebookSourceBrowser from "./is-facebook-source-browser.ts";

type Viewer = {
	readonly isUsingFacebookBrowser: boolean;
};

const Context = createContext<Viewer | undefined>(undefined);

type ViewerProviderProps = {
	readonly userAgent: string;
	readonly children: ReactNode;
};

function ViewerProvider({ userAgent, children }: ViewerProviderProps) {
	return (
		<Context.Provider
			value={{
				isUsingFacebookBrowser: isFacebookSourceBrowser(userAgent),
			}}
		>
			{children}
		</Context.Provider>
	);
}

function useViewer() {
	const value = useContext(Context);
	if (value === undefined) {
		throw new Error("useViewer must be used within a ViewerProvider");
	}

	return value;
}

export { useViewer, ViewerProvider };
