import Modal, { ModalProps } from "~/components/modal.tsx";
import { useTranslator } from "~/i18n/context.tsx";

type ReviewsModalProps = Pick<ModalProps, "open" | "onRequestClose">;

function ReviewsModal({ open, onRequestClose }: ReviewsModalProps) {
	const t = useTranslator();
	return (
		<Modal
			title={t("Reviews")}
			size="full"
			open={open}
			onRequestClose={onRequestClose}
		>
			TODO: Reviews content
		</Modal>
	);
}

export default ReviewsModal;
