import { useEffect } from "preact/hooks";
import {
	corePictureToTransportPicture,
	numberOfBrickColours,
	TransportPictureConfiguration,
} from "@brickme/project-core/src";
import FormErrors from "~/components/forms/form-errors.tsx";
import Modal, { ModalProps } from "~/components/modal.tsx";
import useForm, { FormValues } from "~/hooks/use-form.ts";
import TextField from "~/components/forms/text-field.tsx";
import FormRow from "~/components/forms/form-row.tsx";
import { useTranslator } from "~/i18n/context.tsx";
import { useApiClient } from "~/api/context.tsx";
import {
	useBuildSourcesMediaKeys,
	useCurrentBrickedBuild,
	usePicture,
} from "../context.tsx";
import classes from "./get-instructions-modal.module.css";

type GenerateInstructionsVariables = {
	readonly code: string;
	readonly name: string;
	readonly email: string;
	readonly numberOfKits: number;
	readonly picture: TransportPictureConfiguration;
	readonly sourceImageKey: string;
	readonly backgroundMaskImageKey: string | undefined;
};

type GetInstructionsModalProps = Pick<ModalProps, "open" | "onRequestClose"> & {
	readonly code: string;
	readonly numberOfKits: number;
};

function GetInstructionsModal({
	open,
	code,
	numberOfKits,
	onRequestClose,
}: GetInstructionsModalProps) {
	const t = useTranslator();
	const { picture } = usePicture();
	const bricked = useCurrentBrickedBuild();
	const { sourceImageKey, backgroundMaskImageKey } = useBuildSourcesMediaKeys();
	const apiClient = useApiClient();
	const {
		formProps,
		isWorking,
		formError,
		successValues,
		formRef,
		resetFormState,
	} = useForm({
		onSubmit: async (values: FormValues) => {
			if (!bricked) {
				throw new Error("Project not built");
			}
			if (!sourceImageKey) {
				throw new Error("Waiting for image to upload");
			}

			const { brickCounts } = bricked;
			const email = values.email as string;
			const name = values.name as string;
			await apiClient.mutate<unknown, GenerateInstructionsVariables>(
				`mutation (
					$code: String!
					$name: String!
					$email: String!
					$sourceImageKey: String!
					$numberOfKits: Int!
					$backgroundMaskImageKey: String
					$picture: GeneratepremadekitinstructionsPicture!
				) {
					generatePremadeKitInstructions(
						code: $code,
						name: $name,
						email: $email,
						picture: $picture
						numberOfKits: $numberOfKits
						sourceImageKey: $sourceImageKey
						backgroundMaskImageKey: $backgroundMaskImageKey
					)
				}`,
				{
					name,
					email,
					code,
					numberOfKits,
					sourceImageKey,
					backgroundMaskImageKey,
					picture: corePictureToTransportPicture(
						picture,
						numberOfBrickColours(brickCounts),
					),
				},
			);
			formRef.current?.reset();
		},
	});
	useEffect(() => {
		if (!open) {
			resetFormState();
		}
	}, [resetFormState, open]);

	return (
		<Modal
			open={open}
			containerAs="form"
			containerProps={formProps}
			onRequestClose={onRequestClose}
			title={t("Get instructions")}
			size="small"
			working={isWorking}
			cancelActionButton
			actionButtons={[
				{
					label: t("Get instructions"),
					variant: "primary",
					type: "submit",
					disabled: sourceImageKey === undefined,
				},
			]}
			bodyClassName={classes["modal-body"]}
		>
			<p>
				{t(`We'll generate the instructions for your design and email 
				them to you when you're ready.`)}
			</p>
			<FormRow>
				<TextField
					type="email"
					name="email"
					label={t("Email")}
					required
					autoComplete="email"
					disabled={isWorking}
				/>
			</FormRow>
			<FormRow>
				<TextField
					type="text"
					name="name"
					label={t("Name")}
					required
					autoComplete="given-name"
					disabled={isWorking}
				/>
			</FormRow>
			{sourceImageKey === undefined && (
				<em>{t("Uploading image, please wait")}</em>
			)}
			<FormErrors errors={[formError]} />
			{successValues && (
				<div className={classes.success}>
					{t(
						"We're working on your instructions and will send them to {email} when they're ready",
						successValues,
					)}
				</div>
			)}
		</Modal>
	);
}

export default GetInstructionsModal;
