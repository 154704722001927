import { useState } from "preact/hooks";
import ChevronUpIcon from "~/components/icons/chevron-up-icon.tsx";
import Modal, { ModalProps } from "~/components/modal.tsx";
import { useTranslator } from "~/i18n/context.tsx";
import classes from "./faqs-modal.module.css";

const content = [
	{
		question: "This is some FAQ question # 1",
		answer: (
			<div>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
					sapien eros, efficitur ac iaculis in, malesuada quis purus.
				</p>
				<p>
					Praesent pellentesque ultricies vulputate. Nunc accumsan nibh ac
					fringilla tempus. Praesent dolor leo, condimentum at nulla vitae,
					aliquam tincidunt diam. Vivamus in nibh in nulla feugiat sodales.
					Proin euismod at quam ac efficitur. Curabitur aliquet mattis lacus
					eget mollis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				</p>
			</div>
		),
	},
	{
		question: "This is some FAQ question # 2",
		answer: (
			<div>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
					sapien eros, efficitur ac iaculis in, malesuada quis purus.
				</p>
				<p>
					Praesent pellentesque ultricies vulputate. Nunc accumsan nibh ac
					fringilla tempus. Praesent dolor leo, condimentum at nulla vitae,
					aliquam tincidunt diam. Vivamus in nibh in nulla feugiat sodales.
					Proin euismod at quam ac efficitur. Curabitur aliquet mattis lacus
					eget mollis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				</p>
			</div>
		),
	},
	{
		question: "This is some FAQ question # 3",
		answer: (
			<div>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
					sapien eros, efficitur ac iaculis in, malesuada quis purus.
				</p>
				<p>
					Praesent pellentesque ultricies vulputate. Nunc accumsan nibh ac
					fringilla tempus. Praesent dolor leo, condimentum at nulla vitae,
					aliquam tincidunt diam. Vivamus in nibh in nulla feugiat sodales.
					Proin euismod at quam ac efficitur. Curabitur aliquet mattis lacus
					eget mollis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				</p>
			</div>
		),
	},
	{
		question: "This is some FAQ question # 4",
		answer: (
			<div>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
					sapien eros, efficitur ac iaculis in, malesuada quis purus.
				</p>
				<p>
					Praesent pellentesque ultricies vulputate. Nunc accumsan nibh ac
					fringilla tempus. Praesent dolor leo, condimentum at nulla vitae,
					aliquam tincidunt diam. Vivamus in nibh in nulla feugiat sodales.
					Proin euismod at quam ac efficitur. Curabitur aliquet mattis lacus
					eget mollis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				</p>
			</div>
		),
	},
	{
		question: "This is some FAQ question # 5",
		answer: (
			<div>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
					sapien eros, efficitur ac iaculis in, malesuada quis purus.
				</p>
				<p>
					Praesent pellentesque ultricies vulputate. Nunc accumsan nibh ac
					fringilla tempus. Praesent dolor leo, condimentum at nulla vitae,
					aliquam tincidunt diam. Vivamus in nibh in nulla feugiat sodales.
					Proin euismod at quam ac efficitur. Curabitur aliquet mattis lacus
					eget mollis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				</p>
			</div>
		),
	},
];

type FaqsModalProps = Pick<ModalProps, "open" | "onRequestClose">;

function FaqsModal({ open, onRequestClose }: FaqsModalProps) {
	const [activeIndex, setActiveIndex] = useState<number | undefined>();
	const t = useTranslator();
	return (
		<Modal
			title={t("FAQs")}
			size="large"
			open={open}
			onRequestClose={onRequestClose}
			bodyClassName={classes["faqs-body"]}
		>
			{content.map((c, i) => (
				<details
					open={activeIndex === i}
					onToggle={(e) =>
						setActiveIndex((p) => {
							if (e.currentTarget.open) {
								return i;
							}
							if (p === i) {
								return undefined;
							}
							return p;
						})
					}
				>
					<summary>
						{c.question} <ChevronUpIcon />
					</summary>
					{c.answer}
				</details>
			))}
		</Modal>
	);
}

export default FaqsModal;
