import { useState } from "preact/hooks";
import { ApiProvider } from "~/api/context.tsx";
import Editor from "~/features/editor/editor.tsx";
import Intro from "~/features/intro/intro.tsx";
import { SetupConfig } from "~/features/setup/config.ts";
import SetupFull from "~/features/setup/setup-full.tsx";
import SetupTry from "~/features/setup/setup-try.tsx";
import { I18nProvider, useTranslator } from "~/i18n/context.tsx";
import { ReferenceProvider, useSystemPalette } from "~/reference/context.tsx";
import { ViewerProvider } from "~/viewer/context.tsx";
import Layout from "./layout.tsx";

function App() {
	const [config, setConfig] = useState<
		SetupConfig | "full" | "try" | undefined
	>();
	const systemPalette = useSystemPalette();
	const t = useTranslator();

	if (typeof config === "object") {
		if (!systemPalette) {
			return <Layout>{t("Loading palette")}...</Layout>;
		}

		return (
			<Editor
				numberOfKits={config.numberOfKits}
				sourceImage={config.sourceImage}
				systemPalette={systemPalette}
				code={config.code}
				onRestart={() => {
					setConfig(undefined);
				}}
			/>
		);
	}

	return (
		<Layout>
			{!config && (
				<Intro
					onStartTryItOut={() => setConfig("try")}
					onStart={() => setConfig("full")}
				/>
			)}
			{config === "full" && (
				<SetupFull
					onComplete={setConfig}
					onCancel={() => setConfig(undefined)}
				/>
			)}
			{config === "try" && (
				<SetupTry
					onComplete={setConfig}
					onCancel={() => setConfig(undefined)}
				/>
			)}
		</Layout>
	);
}

type AppContainerProps = {
	readonly userAgent: string;
	readonly apiUrl: string;
};

// TODO: Inject translation files
function AppContainer({ apiUrl, userAgent }: AppContainerProps) {
	return (
		<ApiProvider url={apiUrl}>
			<ReferenceProvider>
				<ViewerProvider userAgent={userAgent}>
					<I18nProvider>
						<App />
					</I18nProvider>
				</ViewerProvider>
			</ReferenceProvider>
		</ApiProvider>
	);
}

export default AppContainer;
