import { useState } from "preact/hooks";
import { SetupConfig } from "./config.ts";
import EnterCode from "./enter-code.tsx";
import EnterNumberOfKits from "./enter-number-of-kits.tsx";
import UploadImage from "./upload-image.tsx";

type EnterCodeStep = {
	readonly type: "enter-code";
};

type NumberOfKitsStep = {
	readonly type: "number-of-kits";
	readonly code: string;
};

type UploadImageStep = {
	readonly type: "upload-image";
	readonly code: string;
	readonly numberOfKits: number;
};

type SetupFullProps = {
	readonly onComplete: (config: SetupConfig) => void;
	readonly onCancel: () => void;
};

function SetupFull({ onComplete, onCancel }: SetupFullProps) {
	const [step, setStep] = useState<
		EnterCodeStep | NumberOfKitsStep | UploadImageStep
	>({
		type: "enter-code",
	});
	switch (step.type) {
		case "enter-code":
			return (
				<EnterCode
					onComplete={(code) => setStep({ type: "number-of-kits", code })}
					onCancel={onCancel}
				/>
			);
		case "number-of-kits":
			return (
				<EnterNumberOfKits
					onComplete={(numberOfKits) =>
						setStep({ type: "upload-image", numberOfKits, code: step.code })
					}
					onCancel={() => setStep({ type: "enter-code" })}
				/>
			);
		case "upload-image":
			return (
				<UploadImage
					onComplete={(sourceImage) =>
						onComplete({
							sourceImage,
							numberOfKits: step.numberOfKits,
							code: step.code,
						})
					}
					onCancel={() => setStep({ type: "number-of-kits", code: step.code })}
				/>
			);
		default:
			return null;
	}
}

export type { SetupConfig };
export default SetupFull;
