import { createContext, ReactNode } from "preact/compat";
import { useMemo, useContext } from "preact/hooks";
import { ApiClient, createApiClient } from "./client.ts";

const ApiContext = createContext<ApiClient | undefined>(undefined);

type ApiProviderProps = {
	readonly url: string;
	readonly children: ReactNode;
};

function ApiProvider({ url, children }: ApiProviderProps) {
	const apiClient = useMemo(() => createApiClient(url), [url]);
	return (
		<ApiContext.Provider value={apiClient}>{children}</ApiContext.Provider>
	);
}

function useApiClient() {
	const apiClient = useContext(ApiContext);
	if (!apiClient) {
		throw new Error("Missing ApiProvider");
	}
	return apiClient;
}

export { ApiProvider, useApiClient };
